import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Nav = styled.nav`
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    li {
      display: inline-block;
      text-align: center;
      margin-right: 1rem;
      a{
        font-style: italic;
        &[aria-current="page"],
        &.active,
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

class PageNavigation extends Component {
  render() {

    return (
      <Nav className="Navigation">
        <ul>
          <li><Link to="/portfolio" activeClassName="active" partiallyActive={true}>portfolio</Link></li>
          <li><Link to="/">feed</Link></li>
          {/*<li><a href="https://www.etsy.com/au/shop/NeathwayStore" target="_blank" rel="noopener noreferrer">store</a></li>*/}
          <li><Link to="/info">info</Link></li>
        </ul>
      </Nav>
    )
  }
}

export default PageNavigation;
