import { Link } from 'gatsby';
import React, { Component } from 'react';
import Container from '../atoms/Container';
import Logo from '../atoms/Logo';
import styled from 'styled-components';
import PageNavigation from './PageNavigation';

const Holder = styled.header`
  margin: 2rem 0 2rem 0;
`;

const Inner = styled.div`
  @media( ${props => props.theme.breakpoints.sm} ) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const LogoHolder = styled.div`
  margin-bottom: 1rem;
  @media( ${props => props.theme.breakpoints.sm} ) {
    margin: 0.5rem 6rem 0 0;
  }
  a {
    display: block;
  }
`;

class Header extends Component {
  render() {
    return (
      <Holder>
        <Container>
          <Inner>
            <LogoHolder>
              <Link to="/" title="home">
                <Logo/>
              </Link>
            </LogoHolder>
            <PageNavigation/>
          </Inner>
        </Container>
      </Holder>
    )
  }
}

export default Header
