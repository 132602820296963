import React, { Component } from 'react';
import Container from '../atoms/Container';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
	padding-top: 4rem;
	padding-bottom: 4rem;
`

class Footer extends Component {
  render() {
    return (
      <Container>
        <FooterWrapper className="Footer">
          <small>© {new Date().getFullYear()}, Joe Neathway Brown</small>
        </FooterWrapper>
      </Container>
    )
  }
}

export default Footer;
