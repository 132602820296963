import { createGlobalStyle } from 'styled-components';
import dolphin from '../../assets/dolphin-02.png'

const GlobalStyle = createGlobalStyle`
  html {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      // Set rems
      font-size: 56.25%; // 1rem = 9px
      @media ( ${props => props.theme.breakpoints.sm} ) {
          font-size: 56.25%; // 1rem = 9px
      }
      @media ( ${props => props.theme.breakpoints.md} ) {
          font-size: 62.5%; // 1rem = 10px
      }
      @media ( ${props => props.theme.breakpoints.lg} ) {
          font-size: 62.5%; // 1rem = 10px
      }
      * { box-sizing: border-box; }
  }

  body {
      min-height: 100%;
      margin: 0;
      // Use system fonts: https://css-tricks.com/snippets/css/system-font-stack/
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      color: ${props => props.theme.colours.white};
      background-color: ${props => props.theme.colours.black};
      cursor: url(${dolphin}) 50 25, auto;
  }
  
  p, .p,
  ol, ul, li,
  code, kbd, pre, samp {
      line-height: 1.4;
      margin-top: 1rem;
      margin-bottom: 1rem;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
      line-height: 1.2;
      margin-top: 2rem;
      margin-bottom: 2rem;
      font-weight: normal;
      a { text-decoration: none; }
      a:hover { text-decoration: none; }
  }

  h1, .h1 { ${props => props.theme.typeStyles( 5 )}; }
  h2, .h2 { ${props => props.theme.typeStyles( 4 )}; }
  h3, .h3 { ${props => props.theme.typeStyles( 3 )}; }
  h4, .h4 { ${props => props.theme.typeStyles( 2 )}; }
  h5, .h5 { ${props => props.theme.typeStyles( 1 )}; }
  h6, .h6 { ${props => props.theme.typeStyles( 0 )}; }
  p, .p, ul, ol, blockquote { ${props => props.theme.typeStyles( 0 )}; }

  li {
      ${props => props.theme.typeStyles( 0 )};
      margin-top: 0;
      margin-bottom: 0;
  }

  small {  ${props => props.theme.typeStyles( -1 )} }
  
  code, kbd, pre, samp {
      font-family: monospace;
      font-size: 16px;
      white-space: normal;
  }
  
  pre { font-size: 16px; }
  
  ul {
      padding-left: 4rem;
      list-style-type: disc;
  }
  
  ol {
      padding-left: 4rem;
      list-style-type: decimal;
  }
  
  video {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    &:focus {
      outline: none;
    }
  }
  
  em, i { font-style: italic; }
  
  strong, b { font-weight: 600; }
  
  blockquote {
      font-weight: 600;
      padding-left: 4rem;
  }
  
  a { 
    color: inherit;
    text-decoration: none; 
    cursor: inherit;
  }
  
  sup, sub {
      vertical-align: baseline;
      position: relative;
      top: -0.4em;
  }
  
  sub { top: 0.4em; }
  
  label {
      ${props => props.theme.typeStyles( -1 )};
      line-height: 1.2;
      font-weight: normal;
  }
  
  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
      display: block;
      font-family:inherit;
      ${props => props.theme.typeStyles( 0 )};
      padding: 0.4rem 1rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 1px ${props => props.theme.colours.black} solid;
      border-radius: 2px;
      line-height: 1.6;
      background-color: transparent;
      -webkit-appearance: none;
      &:focus {
          border: 1px ${props => props.theme.colours.focus} solid;
      }
  }
  
  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
      font-family:inherit;
  }
  
  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
      display: inline-block;
  
      padding: 0 1.5rem;
  
      font-family:inherit;
      font-style: italic;
      ${props => props.theme.typeStyles( -3 )};
      line-height: 1.8;
      text-decoration: none;
      white-space: nowrap;
      border: 1px solid;
      cursor: inherit;
  
      color: inherit;
      background-color: transparent;
      
      @media(${props => props.theme.breakpoints.sm}) {
        padding: 0 1.8rem;
        line-height: 2;
        ${props => props.theme.typeStyles( -2 )};
      }
      
      &:focus {
        outline: none;
      }
      
      &[aria-current="page"],
      &:hover {
        text-decoration: none;
        color: ${props => props.theme.colours.black};
        background-color: ${props => props.theme.colours.white};
        border-color: ${props => props.theme.colours.white};
      }
      
      &.icon {
        padding: 0;
        border: none;
        border-radius: 0;
        line-height: 0;
        svg {
          width: 2rem;
          height: auto;
          display: block;
        }
        &:hover { 
          color: inherit;
          background-color: transparent;
        }
      }
      &.icon.small {
        svg {
          width: 1.5rem;
        }
      }
      
      &.clearStyles {
        padding: 0;
        border: none;
        text-align: left;
        &:hover { 
          color: inherit;
          background-color: transparent;
        }
      }
  }
  
  .errorMsg {
      color: ${props => props.theme.colours.error};
  }
`;

export default GlobalStyle;
