import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from '../components/molecules/Header';
import Footer from '../components/molecules/Footer';
import Transition from '../components/atoms/Transition';

import GlobalStyles from '../components/atoms/GlobalStyles';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../utils/styling';

const Holder = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.main`
  margin-bottom: auto;
`;

class Index extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Holder>
          <GlobalStyles/>
          <Header/>
          <Content>
            <Transition location={this.props.location}>
              <main>{this.props.children}</main>
            </Transition>
          </Content>
          <Footer/>
        </Holder>
      </ThemeProvider>
    )
  }
}


Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;
