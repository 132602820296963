import React, { Component } from 'react';
import styled from 'styled-components';

const Holder = styled.div`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 2rem;
  @media (${props => props.theme.breakpoints.md}) {
    padding: 0 6rem;
  }
  @media (${props => props.theme.breakpoints.xl}) {
    padding: 0 10rem;
  }
`;

class Container extends Component {
  render() {
    return (
      <Holder>
        {this.props.children}
      </Holder>
    )
  }
}

export default Container;
