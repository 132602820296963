import React, { Component } from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 18rem;
  height: auto;
  @media (${props => props.theme.breakpoints.lg}) {
    width: 22rem;
  }
  path {
    fill: ${props => props.theme.colours.white};
  }
`;

class Logo extends Component {
  render() {
    return (
      <Svg width="1586" height="282" viewBox="0 0 1586 282" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Neathway Logo</title>
        <path d="M1135.43 281.68C1117.56 281.02 1104.8 276.68 1092.63 270.99C1084.81 267.32 1080.73 259.6 1077.28 251.99C1073.28 243.1 1070.58 233.69 1066.28 224.9C1063.28 218.78 1065.58 213.44 1071.01 211.66C1078.37 209.24 1084.48 212.86 1086.36 220.71C1088.06 227.8 1088.44 235.19 1091.76 241.91C1097.42 253.34 1106.76 259.01 1119.22 260.14C1139.69 261.99 1156.78 254.37 1171.85 241.21C1176.02 237.57 1180.07 233.79 1184.27 230.21C1190.36 224.97 1192.27 218.21 1191.77 210.49C1191.09 199.93 1191.34 189.33 1190.87 178.78C1190.05 160.46 1191.05 141.98 1186.76 123.92C1186.42 122.5 1186.06 121.08 1185.58 119.7C1185.19 118.6 1184.52 117.56 1183.23 117.48C1181.94 117.4 1181.3 118.48 1180.82 119.56C1171.76 139.41 1143.46 146.07 1127.11 143.86C1117.59 142.57 1113.02 134.55 1109.37 126.52C1103.26 113.07 1100.75 98.5899 1098.05 84.2199C1096.76 77.3799 1094.25 70.6899 1094.8 63.5399C1095 60.8699 1095.31 58.1699 1098.4 57.2599C1101.3 56.3899 1104.34 56.2599 1106.73 58.4299C1108.62 60.2548 1109.86 62.646 1110.26 65.2399C1113.02 79.1299 1115.77 93.0199 1118.76 106.86C1119.48 111.037 1121.06 115.02 1123.39 118.56C1125.94 122.13 1129.25 123.71 1133.55 122.56C1157.13 116.56 1168.67 103.88 1169.36 79.8199C1169.48 75.8999 1169.71 72.0099 1170.16 68.1399C1170.63 64.0699 1172.11 60.3999 1176.68 59.4399C1181.25 58.4799 1184.68 60.9999 1186.75 64.7499C1190.42 71.4496 1193.59 78.4081 1196.25 85.5699C1205.8 110.67 1207.42 136.85 1207.44 163.35C1207.44 181.35 1208.11 199.43 1209.03 217.44C1209.81 232.76 1204.5 244.76 1191.74 253.36C1182.03 259.9 1173.3 267.91 1162.92 273.42C1153.08 278.62 1142.74 282.34 1135.43 281.68Z" fill="white"/>
        <path d="M987.33 67.57C998.79 68.27 1013.45 71.57 1027.52 77.46C1028.27 77.77 1029.03 78.07 1029.8 78.32C1041.53 82.05 1043.21 84.22 1044.05 96.93C1044.8 108.3 1046.51 119.55 1048.34 130.78C1048.92 134.678 1050.83 138.257 1053.74 140.91C1057.09 144.03 1059.9 147.55 1056.98 152.41C1054.27 156.93 1047.56 158.98 1042.25 156.86C1037.83 155.11 1034.54 152.29 1033.51 147.2C1031.23 135.86 1030.6 124.31 1028.91 112.89C1027.98 106.65 1027.07 105.95 1021.85 109.73C1014.17 115.3 1007.72 122.2 1001.56 129.37C997.96 133.56 994.45 137.83 989.79 140.98C981.43 146.63 974.16 146.04 967.06 138.83C959.96 131.62 955.69 122.27 951.45 113.14C948.79 107.39 946.53 101.47 944 95.66C940.92 88.6 942 83.15 948 78.31C958.38 70 970.46 67 987.33 67.57ZM1004.5 92.26C995.5 89.74 987.67 87.62 979.5 88.05C974.436 88.3564 969.571 90.1322 965.5 93.16C963.56 94.56 962.5 96.32 963.21 98.6C965.33 106.01 968.27 113.01 973.84 118.6C975.79 120.6 977.56 120.69 979.84 118.94C989.14 111.67 995.37 101.62 1004.5 92.26V92.26Z" fill="white"/>
        <path d="M815.36 106C814.13 111.06 812.67 116.17 811.68 121.36C810.06 129.513 807.013 137.316 802.68 144.41C799.54 149.68 796.19 154.34 788.94 152.9C781.69 151.46 779.8 145.77 778.18 139.9C773.97 124.55 770.81 108.9 764.18 94.27C761.47 88.27 757.76 82.96 754.29 77.46C752.06 73.93 751.76 70.38 754.29 67.01C758.29 61.74 762.57 61.71 767.29 66.8C773.08 73 776.49 80.54 779.17 88.44C782.04 96.89 784.78 105.38 787.56 113.86C788.05 115.34 788.8 116.59 790.56 116.62C792.32 116.65 792.88 115.21 793.56 113.87C796.25 108.49 796.65 102.63 797.41 96.87C798.743 86.7167 800.16 76.58 801.66 66.46C802.13 63.37 803.02 60.3 806.54 59.17C810.38 57.95 813.12 60.01 815.67 62.41C819.45 65.96 821.19 70.83 823.56 75.23C829.02 85.39 834.14 95.73 839.38 106C844.22 115.48 849.82 116.81 858.91 110.77C861.79 108.844 863.798 105.863 864.5 102.47C866.92 92.2 869.24 81.89 871.33 71.54C873.06 62.93 871.22 54.71 867.66 46.85C864.31 39.46 869.37 30.55 877.38 29.85C881.87 29.47 885.61 33.32 887.47 39.43C892.63 56.34 890.59 73.3 887.78 90.18C886.075 99.6217 883.637 108.916 880.49 117.98C877.3 127.6 870.17 132.63 861.27 135.83C858.015 136.875 854.703 137.733 851.35 138.4C842.28 140.52 836.5 137.97 831.79 129.7C828.09 123.21 824.52 116.63 820.79 110.17C819.71 108.17 818.68 105.92 815.36 106Z" fill="white"/>
        <path d="M656.94 70.0999C656.58 80.8699 657.22 80.7799 667.74 77.2799C683.65 71.9899 696.13 75.1699 707.32 90.6499C715.2 101.54 718.94 114.23 721.54 127.21C723.336 136.797 725.89 146.227 729.18 155.41C730.74 159.59 731.03 163.65 727.34 167.12C723.99 170.28 720.16 171.72 715.75 170.12C711.34 168.52 709.43 164.87 709.14 160.22C708.2 145.22 705.81 130.44 700.85 116.22C698.16 108.52 692.69 102.44 685.97 97.9799C680.84 94.5799 673.14 98.3999 671.32 104.91C670.13 109.478 669.215 114.113 668.58 118.79C666.15 134.56 663.37 150.27 658.33 165.43C655.24 174.72 650.19 183.13 644.9 191.34C642.82 194.56 639.71 195.82 635.99 194.34C631.62 192.57 628.66 189.55 628.4 184.54C628.05 177.64 629.65 170.94 630.53 164.17C633.59 140.62 636.39 117.03 639.69 93.5099C641.33 81.8599 642.38 70.4299 640.48 58.6099C638.24 44.7399 633.28 32.0199 627.21 19.6099C626.14 17.4199 625.07 15.2099 624.21 12.9399C622.21 7.71994 623.98 2.62994 628.21 0.749942C632.44 -1.13006 640.03 0.999942 641.74 5.35994C644.34 11.9999 646.39 18.8699 648.4 25.7199C651.94 37.7199 655.07 49.8699 656.53 62.3599C656.86 64.8799 656.82 67.4999 656.94 70.0999Z" fill="white"/>
        <path d="M546.56 28.68C545.17 30.27 543.61 29.4 542.22 29.56C537.61 30.09 533.22 33.41 533.16 37.76C532.95 47.76 530.09 57.67 531.67 67.84C532.72 74.61 535.58 77.76 542.42 77.92C548.15 78.05 553.74 76.92 559.34 75.85C561.1 75.52 562.84 75.1 564.59 74.72C578.19 71.72 581.59 80.59 579.87 91.14C579.03 96.28 573.08 100.24 565.95 100.67C561.41 100.94 556.85 100.96 552.29 101.08C545.18 101.26 541.96 105.8 544.17 112.53C550.91 133.08 556.33 154 561.65 174.95C563.73 183.13 561.52 190.84 558.59 198.26C556.59 203.34 550.91 203.78 547.01 199.7C543.43 195.96 541.85 191.06 539.66 186.56C535.89 178.83 532.46 170.92 529 163.04C525.36 154.77 526.73 150.71 534.57 146.33C536.5 145.25 537.7 143.93 537.11 141.65C534.73 132.54 532.76 123.29 529.76 114.38C526.76 105.47 521.92 102.93 512.52 103.64C502.73 104.39 497.2 99.4 497.62 90.23C497.93 83.73 500.15 81.46 506.62 81.23C508.084 81.2577 509.544 81.3847 510.99 81.61C517.13 82.27 519.23 80.18 518.45 74.21C516.697 61.948 516.552 49.5094 518.02 37.21C519.1 29.3031 521.114 21.5523 524.02 14.12C524.537 12.5632 525.492 11.1888 526.771 10.1617C528.05 9.13466 529.598 8.49862 531.23 8.32998C534.97 7.83998 536.86 10.33 538.5 13.07C541.05 17.22 543.5 21.46 545.92 25.67C546.243 26.0929 546.468 26.5823 546.579 27.1029C546.69 27.6235 546.683 28.1622 546.56 28.68V28.68Z" fill="white"/>
        <path d="M331.45 134.81C331.13 127.63 333.97 121.42 337.73 115.65C350.31 96.3699 367.4 84.8799 391.22 85.6499C395.63 85.7899 396.93 83.0699 397.65 79.7699C398.59 75.4499 398.81 70.1699 404.3 69.6699C409.79 69.1699 412.98 73.7599 415.3 78.0999C421.35 89.4599 425.97 101.46 430.11 113.62C436.11 131.35 442.54 148.91 453.89 164.13C455.8 166.7 456.62 169.31 454.97 172.27C453.18 175.47 450.33 176.63 446.78 176.51C438.54 176.25 432.15 169.89 429.16 159.51C426.42 150.01 424.16 140.26 418.59 131.84C417.74 130.56 417.47 128.44 415.29 128.84C413.11 129.24 412.49 131.06 412.17 132.84C410.93 139.72 409.87 146.63 408.67 153.52C407.07 162.68 400.89 169.64 391.87 169.9C378.46 170.3 364.79 170.58 352.04 165.15C341.6 160.73 330 148.61 331.45 134.81ZM379.09 150.24C385.25 149.99 389.48 148.24 391.53 142.86C394.95 133.98 396.39 124.66 397.81 115.33C398.91 108.17 398.57 107.73 391.46 106.61C390.46 106.46 389.52 106.38 388.55 106.32C370.78 105.15 359.18 115.17 349.86 128.45C345.49 134.67 348.24 141.45 355.25 145C363 148.9 371.84 147.92 379.09 150.24Z" fill="white"/>
        <path d="M182.64 111.41C183.04 105.41 183.1 99.4099 185.54 93.6799C186.697 90.7989 188.519 88.2329 190.859 86.1923C193.199 84.1516 195.989 82.6943 199 81.9399C211.673 78.2855 224.811 76.4967 238 76.6299C243.86 76.6999 249.73 76.1499 255.57 76.4599C266.43 77.0299 272.2 88.4599 265.35 96.9299C254.41 110.42 243.86 124.42 229.77 135.01C223.556 139.767 216.427 143.191 208.83 145.07C202.3 146.65 200.02 149.31 200.61 155.65C201.43 164.56 202.43 173.47 203.79 182.31C205.05 190.5 207.94 192.88 215.96 192.31C230.59 191.22 245.21 189.78 257.89 181.21C262.185 178.242 265.945 174.567 269.01 170.34C271.08 167.55 273.55 165.6 277.27 166.41C280.99 167.22 282.35 170.15 283.32 173.41C286.62 184.41 283.42 192.21 273.22 197.31C261.61 203.12 250.14 209.39 237.36 212.37C226.26 214.96 214.99 215.43 204.17 211.32C193.35 207.21 189.58 197.49 188.17 186.92C186.92 177.92 186.31 168.8 185.43 159.74C184.81 153.37 181.9 148.48 175.74 146.32C168.14 143.65 164.02 137.87 161.06 130.97C159.36 126.97 159.69 123.05 163.5 120.5C166.97 118.18 170 120.15 172.68 122.58C173.611 123.505 174.614 124.355 175.68 125.12C178.94 127.25 181.95 126.06 182.4 122.2C182.78 118.6 182.58 115 182.64 111.41ZM230.64 97.7999C225.17 98.5799 218.83 99.0999 212.64 100.49C207.77 101.59 203.01 103.49 199.57 107.55C196.63 111.02 196.13 118.94 198.9 122.19C202.36 126.19 206 123.43 209.51 122.1C219.243 118.327 227.808 112.052 234.34 103.91C235.43 102.59 237.26 101.3 236.26 99.3099C235.26 97.3199 233.19 98.1599 230.67 97.7999H230.64Z" fill="white"/>
        <path d="M16 206.69C13 205.5 12.7 203.28 11.84 201.38C6 188.69 3.14 175.1 0.77 161.56C-0.72 153.1 0.0400002 144.29 2.28 135.56C5.49 123 9.37 110.87 16.28 99.86C19.88 94.11 23.12 88 29.28 84.27C41.54 76.8 54.44 78.8 66.17 87.27C76.9 95 82.75 106.45 87.17 118.41C93.77 136.41 100.64 154.29 105.75 172.78C107.969 180.414 109.759 188.166 111.11 196C111.85 200.63 110.49 204.8 108.65 208.74C107.12 212.04 103.87 213.03 100.32 212.25C96.77 211.47 94.59 209.48 94.25 205.62C93.4 195.72 92.61 185.75 89.46 176.29C83.46 158.16 79.98 139.03 69.74 122.46C65.62 115.8 60.74 109.72 53.98 105.39C47.22 101.06 41.34 101.19 36.12 107.24C23 122.41 18.83 140 18.09 160.29C17.5877 169.776 19.2994 179.249 23.09 187.96C26.26 195 21.64 205.9 16 206.69Z" fill="white"/>
        <path d="M1502.88 17.7799C1522.67 18.6199 1539.79 23.7199 1553.81 36.9099C1555.23 38.2499 1556.81 39.4499 1558.22 40.7699C1560.33 42.7699 1562.32 42.9299 1564.67 40.9599C1572.58 34.3199 1577.03 36.7699 1581.52 46.3799C1584.2 52.1299 1584.97 58.3099 1585.72 64.3299C1586.54 70.9099 1584.16 77.5099 1582.66 83.9499C1580.81 92.2116 1579.78 100.636 1579.58 109.1C1579.2 121.28 1579.34 133.44 1577.11 145.56C1573.02 167.75 1563.27 187.06 1548.86 204.16C1535.02 220.58 1519.06 234.54 1500.19 245.02C1489.11 251.16 1476.91 254.78 1464.67 253.93C1436.48 251.93 1410.32 242.84 1395.94 216.84C1384.7 196.55 1372.94 176.08 1366.77 153.21C1363.14 139.68 1365.86 127.55 1373.41 116.07C1386.09 96.7799 1398.5 77.2999 1411.26 58.0699C1415.93 51.0699 1421.12 44.3099 1426.36 37.6799C1429.47 33.5842 1433.4 30.1869 1437.91 27.7081C1442.41 25.2292 1447.39 23.724 1452.51 23.2899C1467.88 21.7399 1483.12 18.9899 1498.51 17.9099C1500.33 17.7799 1502.12 17.7999 1502.88 17.7799ZM1497 36.4399C1482 36.2999 1467.25 38.9199 1452.54 41.7799C1446.45 42.9599 1441.09 44.6799 1436.88 49.6299C1429.8 57.9299 1422.65 66.1699 1416.82 75.4199C1406.67 91.5099 1397.14 108.02 1386.42 123.72C1380.98 131.72 1381.48 138.98 1384.31 147.18C1390.01 163.7 1398.5 178.88 1406.41 194.36C1414.54 210.28 1425.34 222.95 1442.83 229.04C1459.76 234.93 1476.51 235.14 1492.56 227.04C1509.45 218.56 1523.68 206.3 1536.37 192.4C1550.66 176.996 1559.5 157.319 1561.51 136.4C1563.77 114.6 1564.06 92.5799 1568.78 71.0499C1569.71 66.8099 1567.22 64.3399 1563.43 63.7699C1556.12 62.6899 1550.2 58.9399 1545.17 54.0899C1531.63 41.1199 1515.18 36.5799 1497 36.4399Z" fill="white"/>
        <path d="M1479.85 198.58C1468.69 197.89 1457.76 195.99 1449.97 186.36C1445.06 180.29 1445.34 174.23 1450.42 168.36C1452.79 165.61 1454.78 165.55 1457.32 168C1460.63 171.367 1464.28 174.383 1468.21 177C1475.85 181.82 1483.58 180.11 1491.12 177.13C1497.75 174.51 1504.22 171.44 1510.58 168.22C1513.58 166.69 1516.11 165.72 1519.01 168.22C1522.23 171.03 1522.45 174.3 1520.91 177.92C1518.81 182.892 1515.25 187.106 1510.69 190C1501.4 196 1491 198.22 1479.85 198.58Z" fill="white"/>
        <path d="M1439.62 111C1439.5 107.18 1442.01 103.61 1444.84 100.22C1447.49 97.04 1450.52 98.57 1453.16 100.05C1456.58 101.96 1458.24 105.14 1457.16 109.05C1455.62 114.65 1451.57 118.12 1446.26 119.87C1442 121.31 1439.28 117.85 1439.62 111Z" fill="white"/>
        <path d="M1507.88 93.7499C1508.4 87.0899 1512.28 82.82 1517.54 83.12C1521.7 83.35 1525.9 87.6899 1525.54 91.4C1525.09 96.24 1518.35 101.69 1513.35 101.27C1509.34 100.93 1507.51 98.4233 1507.88 93.7499Z" fill="white"/>
      </Svg>
    )
  }
}

export default Logo;
